// src/components/ChatResponse.js
import React from 'react';
import { marked } from 'marked';

const ChatResponse = ({ text }) => {
  // Create a custom renderer
  const renderer = new marked.Renderer();

  // Override function to render links
  renderer.link = (href, title, text) => {
    return `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title}">${text}</a>`;
  };

  
  const markdownToHtml = marked(text, { renderer });

  return (
    <div style={responseStyle} dangerouslySetInnerHTML={{ __html: markdownToHtml }} />
      // {/* {text} */}
      
    // </div>
  );
};

const responseStyle = {
  width: '100%',
  margin: '10px 0',
  padding: '10px',
  fontSize: '13px',
  lineHeight: '1.5',
  wordBreak: 'break-word',
  fontFamily: 'helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif', // Added font family
  fontStyle: 'normal', // Added font style
  fontVariantCaps: 'normal', // Added font variant caps
  fontVariantEastAsian: 'normal', // Added font variant east-asian
  fontVariantLigatures: 'normal', // Added font variant ligatures
  fontVariantNumeric: 'normal', // Added font variant numeric
  fontWeight: '400', // Added font weight
};

export default ChatResponse;
