// src/components/MainContent.js
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import ChatResponse from "./ChatResponse";
import PromptCard from "./PromptCard";
import FollowPromptCard from "./FollowPromptCard";
import getInitials from "./Initials";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';

const MainContent = () => {
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { base, chatId } = useParams();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  let userName = null;
  const [ans, setAnswer] = useState('');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (localStorage.getItem("firstName") && localStorage.getItem("lastName")) {
    userName =
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName");
  }
  const userInitials = userName ? getInitials(userName) : getInitials("You");

  useEffect(() => {
    scrollToBottom(); // Step 3: Scroll to bottom whenever responses change
  }, [response]);

  const handlePromptClick = (promptText) => {
    handleSendMessage(promptText, localStorage.getItem("language"));
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);
  }, []);

  const handleSendMessage = async (input, language, fileName = null) => {
    const lastResponse =
      response.length > 0 ? response[response.length - 1] : null;

    if (lastResponse && lastResponse.type === "follow-up-prompts") {
      setResponse((prevArray) => prevArray.slice(0, -1));
    }

    if (fileName) {
      setResponse((prevArray) => [
        ...prevArray,
        {
          text: `Uploaded File: ${fileName}`,
          message: `Uploaded File: ${fileName}`,
          type: "file",
          user: "user",
        },
      ]);
    } else {
      setResponse((prevArray) => [
        ...prevArray,
        {
          text: input,
          message: input,
          type: "question",
          user: "user",
        },
      ]);
    }
  console.log('input   :',input)
    setIsLoading(true); // Start loading
    try {
      const newData = await answer(input, language);
      if (newData) {
        setResponse((prevArray) => [
          ...prevArray,
          {
            text: newData["answer"],
            message: newData["answer"],
            type: "answer",
            user: "bot",
          },
        ]);

        if (newData["prompts"] && newData["prompts"].length) {
          setResponse((prevArray) => [
            ...prevArray,
            {
              prompts: newData["prompts"],
              type: "follow-up-prompts",
              user: null,
            },
          ]);
        } else {
          // const defaultPrompts = await fetchDataFromServer();
          // if (defaultPrompts && defaultPrompts.prompts && defaultPrompts.prompts.length) {
          //   setResponse((prevArray) => [
          //     ...prevArray,
          //     {
          //       prompts: defaultPrompts.prompts,
          //       type: "follow-up-prompts",
          //       user: null,
          //     },
          //   ]);
          // }
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const answer = async (input, language) => {
  //   try {
  //     const response = await fetch('http://localhost:5000/file_answer', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: localStorage.getItem("authToken"),
  //       mode: 'no-cors',
  //     },
  //     body: JSON.stringify({ input }),
  //   });
  //   if (response.ok) {
  //     setAnswer(response.data.ans);
  //     return await response.json();
  //   }
    
  // } catch (error) {
  //   console.error('Error getting answer:', error);
  // }
    const token = localStorage.getItem("authToken");
    try {
      let queryParam = "base=" + base + "&id=" + chatId;
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/ask?" + queryParam,
        {
          method: "POST",
          body: JSON.stringify({ question: input, language: language }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error("Unable to get the response!");
      }
    } catch (error) {
      console.error("Error in fetching: ", error);
      throw error;
    }
  };

  const fetchDataFromServer = async () => {
    try {
      // const initialData = await answer("Initial question or command to fetch data");
      const token = localStorage.getItem("authToken");
      let queryParam = "base=" + base;
      if (chatId) {
        queryParam = queryParam + "&id=" + chatId;
      }
      const initialData = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/chat?" + queryParam,
        {
          method: "GET",
         
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (initialData.ok) {
        let data = await initialData.json();
        if (!chatId && "id" in data) {
          const newUrl = "/chat/" + data["base"] + "/"+ data["id"];
          navigate(newUrl);
        }
        return data;
      } else {
        throw new Error("Unable to get the response!");
      }
    } catch (error) {
      console.error("Error fetching initial data: ", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchDataFromServer();
        const key = 'prompts';
        let prompts = response[key];

        console.log("response[key]:", response[key]);
        console.log("prompts:", prompts);

        // If prompts is undefined or null, set it to an empty array
        if (!prompts) {
          prompts = [];
        }

        // Convert prompts to an array if it's not already one
        if (!Array.isArray(prompts)) {
          prompts = [prompts];
        }
        if (prompts.length > 0) {
          
          setResponse([
            {
              prompts: prompts.map(item => {
                if (Array.isArray(item.prompts)) {
                  return item.prompts;
                } else {
                  return JSON.stringify(item);
                }
              }).flat(),
              type: "prompt",
              user: null,
            },
          ]);
        } 
        else {
          setResponse([
            {
              prompts: [
                "Course-Related Questions",
                "Enrollment and Fees",
                "Technical and Support",
                "General Questions",
                "Additional Services"
              ],
              type: "prompt",
              user: null,
            },
          ]);
        }
        if (response.chat) {
          setResponse((prevArray) => [...prevArray, ...response["chat"]]);
        }
        if (response.followup_prompts && response.followup_prompts.length && response.chat) {
          setResponse((prevArray) => [
            ...prevArray,
            {
              prompts: response.followup_prompts,
              type: "follow-up-prompts",
              user: null,
            },
          ]);
        }
      } catch (error) {
        console.error("Error fetching initial data: ", error);
      }
    }
    fetchData();
  }, []);

  return (
    <MainContainer>
      <div className="MainContent" style={MainContentStyle}>
        <div style={messagesContainerStyle}>
          {response.map((msg, index) =>
            msg.type === "prompt" ? (
              <div style={promptCardsContainerStyle}>
                {msg.prompts.map((prompt, promptIndex) => (
                  <PromptCard
                    key={promptIndex}
                    text={prompt}
                    size={msg.prompts.length}
                    onClick={() => handlePromptClick(prompt)}
                  />
                ))}
              </div>
            ) : msg.type === "follow-up-prompts" ? (
              <div style={followPromptCardsContainerStyle}>
                {msg.prompts.map((prompt, promptIndex) => (
                  <FollowPromptCard
                    key={promptIndex}
                    text={prompt.display_text}
                    index={promptIndex}
                    onClick={() => handlePromptClick(prompt.prompt)}
                  />
                ))}
              </div>
            ) : msg.user === "user" ? (
              <div>
                <div style={userStyle}>
                  <span style={initialsStyle}>{userInitials}</span>
                  {userName || "You"}
                </div>
                <ChatResponse key={index} text={msg.message} type={msg.type} />
              </div>
            ) : (
              <div>
                <div style={userStyle}>
                  <img src={"/pulsera.png"} alt={"pulsera"} style={iconStyle} />
                  Pulsera
                </div>
                <ChatResponse key={index} text={msg.message} type={msg.type} />
              </div>
            )
          )}
          <div ref={messagesEndRef} />
        </div>
        <ChatInput onSendMessage={handleSendMessage} isLoading={isLoading} />
      </div>
    </MainContainer>
  );
};

const authButtonsContainerStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "10px 20px",
};

const buttonStyle = {
  textDecoration: "none",
  color: "#fff",
  backgroundColor: "#007bff",
  padding: "10px 25px",
  margin: "0 10px",
  borderRadius: "5px",
};

const MainContainer = styled.div`
  @media (max-width: 900px) {
    margin-bottom: 30px;
  }
  @media (max-width: 500px) {
    margin-bottom: 40px;
  }
`;

const MainContentStyle = {
  display: "flex",
  flexDirection: "column",
  height: "90vh",
  // height: "100%",
  // border: "2px solid gray",
  // boxShadow: "0 2rem 4rem #000",
  width: "75vw",
  overflow: "hidden",
};

const followPromptCardsContainerStyle = {
  justifyContent: "space-around",
  flexWrap: "wrap",
  alignItems: "left",
  margin: "10px, 0",
};

const promptCardsContainerStyle = {
  display: "flex",
  overflowY: "auto",
  justifyContent: "space-around",
  flexWrap: "wrap",
};

const messagesContainerStyle = {
  overflowY: "auto",
  flexDirection: "column",
  flex: 1,
  padding: "10px",
  margin: "10px 0",
};

const userStyle = {
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
};

const initialsStyle = {
  display: "inline-block",
  marginRight: "8px",
  backgroundColor: "#bbd9fc",
  color: "#fff",
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  textAlign: "center",
  lineHeight: "24px",
  fontWeight: "normal",
};

const iconStyle = {
  display: "inline-block",
  marginRight: "8px",
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: "#bbd9fc",
};

export default MainContent;

