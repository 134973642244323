import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FiEdit3, FiTrash2, FiShare2 } from 'react-icons/fi';
import { useLocation } from "react-router-dom";

const ChatList = () => {
    const [messages, setMessages] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editedName, setEditedName] = useState('');
    let authToken = localStorage.getItem("authToken");
    const navigate = useNavigate();
    const location = useLocation();
    const currentChatId = location.pathname.split('/chat/')[1];

    // Fetching the list of chats when the component mounts
    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        const API_URL = process.env.REACT_APP_API_BASE_URL + '/chats';
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        };

        try {
            const response = await fetch(API_URL, { method: 'GET', headers: headers });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setMessages(data); // Assuming the API returns an array of messages
        } catch (error) {
            console.error("Failed to fetch messages:", error);
        }
    };

    const handleChatClick = async (messageId, messageBase) => {
        const newUrl = "/chat/" + messageBase + '/' + messageId;
        navigate(newUrl);
        window.location.reload();
    };

    const handleCreateNewChat = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ name: 'New Chat' }),
            });

            if (response.ok) {
                const newChat = await response.json();
                // Refetch the updated list of chats
                await fetchMessages();
                navigate(`/chat/${newChat.base}/${newChat.id}`);
            } else {
                console.error("Failed to create a new chat:", response.status);
            }
        } catch (error) {
            console.error("Error creating a new chat:", error);
        }
    };

    const handleEditClick = (messageId, name) => {
        setEditMode(messageId);
        setEditedName(name);
    };

    const handleDeleteClick = async (messageId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this chat?");
        if (!isConfirmed) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat?id=${messageId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`Failed to delete chat: ${response.status}`);
            }
            setMessages(prevMessages => prevMessages.filter(message => message.id !== messageId));
        } catch (error) {
            console.error("Error deleting the chat:", error);
        }
    };

    const handleSaveClick = async (messageId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chat?id=${messageId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify({ name: editedName }),
            });
            if (!response.ok) {
                throw new Error(`Failed to update chat name: ${response.status}`);
            }
            const updatedChat = await response.json();
            setMessages(prevMessages =>
                prevMessages.map(msg =>
                    msg.id === messageId ? { ...msg, name: updatedChat.name } : msg
                )
            );
            setEditMode(false);
        } catch (error) {
            console.error("Error updating the chat name:", error);
        }
    };

    return (
        <ChatListContainer>
            <div style={chatContainerStyle}>
                <div
                    style={newChatStyle}
                    onClick={() => handleCreateNewChat()}>
                    <span className="text"> + New Chat</span>
                </div>
                {Array.isArray(messages) && messages.length > 0 ? (
                    messages.map(message => (
                        <div
                            key={message.id}
                            style={currentChatId === message.id.toString() ? highlightedChatStyle : chatStyle}
                            onClick={() => { if (editMode !== message.id) { handleChatClick(message.id, message.base); } }}
                        >
                            {editMode === message.id ? (
                                <>
                                    <input
                                        type="text"
                                        value={editedName}
                                        onChange={(e) => setEditedName(e.target.value)}
                                        style={{ ...inputStyle }}
                                    />
                                    <button onClick={() => handleSaveClick(message.id)} style={buttonStyle}>Save</button>
                                    <button onClick={() => setEditMode(false)} style={buttonStyle}>Cancel</button>
                                </>
                            ) : (
                                <>
                                    <span className="text">{message.name}</span>
                                    <div style={iconContainerStyle}>
                                        <FiEdit3
                                            style={iconStyle}
                                            onClick={(e) => { e.stopPropagation(); handleEditClick(message.id, message.name); }}
                                        />
                                        <FiTrash2
                                            style={iconStyle}
                                            onClick={(e) => { e.stopPropagation(); handleDeleteClick(message.id); }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No messages available</p>
                )}
            </div>
        </ChatListContainer>
    );
};

const ChatListContainer = styled.div`
  flex: 1;
  background-color: #FFF;
`;

const chatContainerStyle = {
    width: '100%',
    margin: 'auto',
    background: '#f9f9f9',
    height: '90vh',
    overflow: 'auto',
}

const chatStyle = {
    width: '100%',
    background: '#a9a9a9',
    padding: '3px 10px',
    height: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '30px',
    border: '2px solid #007bff',
    borderRadius: '10px',
    marginBottom: '5px',
    cursor: 'pointer',
}

const newChatStyle = {
    width: '100%',
    background: '#a9a9a9',
    padding: '3px 10px',
    height: '40px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '30px',
    border: '2px solid #007bff',
    borderRadius: '10px',
    marginBottom: '5px',
    cursor: 'pointer',
}

const iconContainerStyle = {
    float: 'right',
};

const iconStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
};

const inputStyle = {
    // Input field style
};

const buttonStyle = {
    // Button style
};

const highlightedChatStyle = {
    ...chatStyle,
    borderColor: '#007bff',
    boxShadow: '0px 0px 8px rgba(0, 123, 255, 0.5)',
    backgroundColor: '#f0f8ff',
};

export default ChatList;
