import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIcons>
        <a
          href="https://www.linkedin.com/company/mdxblocks/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/linkedin_logo_icon.png" alt="LinkedIn" />
        </a>
        <a
          href="https://www.youtube.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/youtube_logo_icon.png" alt="Youtube" />
        </a>
        <a
          href="https://twitter.com/Krishna_Bayanna"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/twitter_logo_icon.png" alt="Twitter" />
        </a>
        <a
          href="https://www.facebook.com/krishna.bayanna.1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/facebook_logo_icon.png" alt="Facebook" />
        </a>
        {/* <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src="/instagram_logo_icon.png" alt="Instagram" />
          </a> */}
      </SocialIcons>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  height: 5vh;
  background: #bbd9fc;
  color: black;
  margin-top: -3px;

  @media (max-width: 900px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 2px;
  margin-right: 20px;
  & > a {
    margin-right: 10px;
    &:last-child {
      margin-right: 0; // Increase the margin for the last icon
    }
    img {
      width: 17px;
      height: 17px;
    }
    @media (max-width: 900px) {
      margin-top: 5px;
    }
  }
`;

export default Footer;
