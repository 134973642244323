import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useToggle } from "./ToggleContext";
import axios from 'axios';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const { isVideoSelected, setIsVideoSelected } = useToggle();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = async (e) => {
    const token = localStorage.getItem("authToken");
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/signout`, null, {  // Note that the body is 'null' since headers are in the config object
      headers: {
        'Authorization': `Bearer ${token}`,  // Use the token from local storage
        'Content-Type': 'application/json', // Ensure content type is correct
      },
    });
    localStorage.clear();
    // localStorage.removeItem('authToken'); // Clear the authentication token or user data from local storage
    // localStorage.removeItem('refreshToken'); // Clear the authentication token or user data from local storage
    // localStorage.removeItem('firstName'); // Clear the authentication token or user data from local storage
    // localStorage.removeItem('lastName'); // Clear the authentication token or user data from local storage
    // localStorage.removeItem('email'); // Clear the authentication token or user data from local storage
    setIsLoggedIn(false); // Update the isLoggedIn state to false
    navigate("/signin"); // Redirect to the home page or login page. Adjust the path as needed.
  };

  // Handler for clicking the "Video" label
  const handleVideoClick = () => {
    setIsVideoSelected(true);
    // localStorage.setItem("isChatsSelected", false);
  };

  // Handler for clicking the "Chats" label
  const handleChatsClick = () => {
    setIsVideoSelected(false);
    // localStorage.setItem("isChatsSelected", true);
  };

  return (
    <HeaderContainer>
       {/* {isLoggedIn ? (
        <FlexItem>
           <ToggleContainer>
            <Label onClick={handleVideoClick} selected={isVideoSelected}>
              Video
            </Label>
            <ToggleSwitch>
              <ToggleThumb isVideoSelected={isVideoSelected} />
            </ToggleSwitch>
            <Label onClick={handleChatsClick} selected={!isVideoSelected}>
              Chats
            </Label>
          </ToggleContainer> 
          {isLoggedIn && (
            <TabsContainer>
              <Tab
                onClick={() => setIsVideoSelected(true)}
                selected={isVideoSelected}
              >
                Video
              </Tab>
              <Tab
                onClick={() => setIsVideoSelected(false)}
                selected={!isVideoSelected}
              >
                Chats
              </Tab>
            </TabsContainer>
          )}
        </FlexItem>
      ) : (
        <FlexItem></FlexItem>
      )}  */}
      <TitleContainer>
        <Title>Welcome to Pulsera</Title>
      </TitleContainer>
      <RightContainer>
        {isLoggedIn && (
          <SignOutButton onClick={handleLogout}>Sign Out</SignOutButton>
        )}
      </RightContainer> 
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #bbd9fc;
  padding: 10px 200px;
  height: 5vh;
  width: 100%;
`;

const FlexItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // &:first-child {
  //   justify-content: flex-start;
  // }
  // &:last-child {
  //   justify-content: flex-end;
  // }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  margin: 3px 5px 0px 5px;
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  color: ${({ selected }) => (selected ? "#007bff" : "#333")};
`;

const ToggleSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ddd;
  border-radius: 10px;
  margin: 3px 5px 0px 5px;
`;

const ToggleThumb = styled.div`
  position: absolute;
  top: 2px;
  left: ${({ isVideoSelected }) => (isVideoSelected ? "2px" : "22px")};
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 0.3s ease;
`;

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 2px solid #007bff;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 1px;
  color: ${({ selected }) => (selected ? "#007bff" : "#333")};
  background-color: ${({ selected }) => (selected ? "#e7f3ff" : "transparent")};
  
  border-radius: 10px; /* Adjust this value to increase or decrease the rounding */
  
  &:hover {
    background-color: #e7f3ff;
  }

`;


const Title = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const SignOutButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default Header;
